<template>
  <!-- <footer>
    <div class="shop">
      <img src="../assets/img/shop.png" alt="">
    </div>
    <div class="container">
      <img src="../assets/img/pokemon_store.png" alt="">
      <div class="info">
        <p>blog: <a href="www.hackdapp.com">www.hackdapp.com</a></p>
        <p>极客豚: <a href="http://ptun.io">http://ptun.io</a></p>
        <p>實體店面：真新鎮大木路一段6號</p>
        <p>營業時間：00:00-24:00</p>
      </div>
      <div>
        <p>作者：icguanyu <a href="https://github.com/icguanyu" target="_blank">Github</a>｜<a href="https://codepen.io/icguanyu/" target="_blank">Codepen</a></p>
        <p>練習作品｜<a href="https://www.pokemon.com/us/" target="_blank">圖片來源：https://www.pokemon.com/us/</a></p> 
      </div>
    </div>
  </footer> -->
  <footer class="footer-black">
        <div class="container">
            <hr class="footer-hr">
            <div class="row">
                <!-- <div class="col-sm-3">
                    <img style="width: 100%;" src="https://hack.bg/wp-content/themes/hackblockchain/assets/images/hack-logo.png" alt="hack - Blockchain &amp; DLT Solutions">
                    <ul>
                        <li><a href="mailto:hackdapp@gmail.com" title="Get in touch: hello@hack.bg">hello@hack.bg</a></li>
                        <li><a href="tel:00359898725100" title="Call us: 00 359 898 725 100">00 359 898 725 100</a></li>
                        <li><abbr title="We are based in Sofia, Bulgaria">Floor 4, 47 Cherni Vrah blvd. Sofia 1407, Bulgaria</abbr></li>
                        <li>
                            <a href="//facebook.com/hackbg" target="_blank" title="hack - facebook page"><i class="fab fa-facebook"></i></a>
                            <a href="//linkedin.com/company/hackbg-blockchain-web-software-solutions" target="_blank" title="hack - linkedin company page"><i class="fab fa-linkedin"></i></a>
                            <a href="//twitter.com/hack_bg" target="_blank" title="hack - twitter company page"><i class="fab fa-twitter"></i></a>
                            <a href="//github.com/hackbg" target="_blank" title="hack - github page"><i class="fab fa-github"></i></a>
                            <a href="//gitter.im/hack-blockchain-development/lobby" target="_blank" title="hack - gitter chat"><i class="fab fa-gitter"></i></a>
                            <a href="//medium.com/hack" target="_blank" title="hack - blog on medium"><i class="fab fa-medium"></i></a>
                        </li>
                    </ul>
                </div>
                
                <div class="col-sm-3">
                    <h5>Services</h5>
                    <ul>
                        <li><a href="/dlt-blockchain-development-services/blockchain-software-development/" title="Blockchain Development services">Blockchain Development</a></li>
                        <li><a href="/dlt-blockchain-development-services/smart-contracts-development/" title="Smart contract development services">Smart Contracts</a></li>
                        <li><a href="/dlt-blockchain-development-services/smart-contract-audits/" title="Blockchain Consulting and Advisory">Smart contract Audits</a></li>
                        <li><a href="/dlt-blockchain-development-services/ico-initial-coin-offering/" title="Funrasing and crowsale">Crowdsale</a></li>
                        <li><a href="/dlt-blockchain-development-services/blockchain-consulting/" title="Blockchain Consulting and Advisory">Consulting</a></li>
                        <li><a href="/dlt-blockchain-development-services/decentralized-applications-development/" title="Decentralized Applications Consulting and Development">Decentralized Applications Development</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5>Products</h5>
                    <ul>
                        <li><a href="//github.com/mradkov/vscode-sophia">Sophia VSCode</a></li>
                        <li><a href="//waellet.com">Waellet</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5>Company</h5>
                    <ul>
                        <li><a href="/#clients-and-partners" title="Our clients portfolio">Clients and partners</a></li>
                        <li><a href="/events" title="Events organized by hack">Events</a></li>
                        <li><a href="/careers" title="Careers at hack">Careers</a></li>
                        <li><a href="/blog" title="hack - blog">Blog</a></li>
                        <li><a href="//github.com/hackbg/hack-media-kit/" target="_blank" title="hack media kit">Press</a></li>
                        <li><a href="/privacy-policy/" title="hack privacy policy">Privacy Policy</a></li>
                    </ul>
                </div> -->
                
            </div>
        </div>
        <div class="footer-copyright text-center">
            <p>© hackdapp.com Ltd - All rights reserved, 2016 - 2019</p>
        </div>
    </footer>
</template>

<script>
import $ from "jquery";

export default {
  name: "bottom"
};
</script>

<style lang="scss" scoped>
// footer {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   .shop{
//     img{
//       max-width: 800px;
//       width: 100%;
//     }
//   }
//   .container{
//     display: flex;
//     font-size: 14px;
//     color: #666;
//     align-items: center;
//     justify-content: space-between;
//     max-width: 1080px;
//     width: 100%;
//     padding: 20px 0;
//     margin: 0 auto;
//     border-top: 1px solid #aaa;
//     img{
//       height: 40px;
//     }
//     a{
//       color: #666;
//     }
//   }
// }
// @media screen and (max-width: 640px) {
//   footer{
//     .container{
//       padding: 10px 20px;
//       flex-direction: column;
//       align-items: flex-start;
//       box-sizing: border-box;
//     }
//   }
// }
.footer-hr {
  border-top: 4px dashed #666;
}

footer{
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .footer-copyright {
    text-align: center;
  }
}
</style>

import { render, staticRenderFns } from "./productInfo.vue?vue&type=template&id=c3e4ef10&scoped=true&"
import script from "./productInfo.vue?vue&type=script&lang=js&"
export * from "./productInfo.vue?vue&type=script&lang=js&"
import style0 from "./productInfo.vue?vue&type=style&index=0&id=c3e4ef10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3e4ef10",
  null
  
)

component.options.__file = "productInfo.vue"
export default component.exports